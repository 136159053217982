/* global LazyLoad */
import inViewObserver from "./in-view-observer";
import nav from "./nav";
import Expander, { ExpandersController } from "./expander";
import Modal from "./modal";
import CustomDropdown from "./dropdown";
import carouselsInit from "./carousels";
import videoInit from "./video";
import truncaterInit from "./truncater";
import iframeLazyLoadInit from "./iframe-lazy-load";
import forms from "./forms";
import { initialiseBasketEvents } from "./basket";
import { addToBasketTrigger } from "./ecom";

function expanderInit() {
  const expanders = [...document.querySelectorAll<HTMLElement>(".js-expander")];

  const controllers = document.querySelectorAll<HTMLElement>(
    ".js-expandersController"
  );
  const controllerExpanders = [...controllers].flatMap(function (
    controller: HTMLElement
  ) {
    return [...controller.querySelectorAll<HTMLElement>(".js-expander")];
  });

  const standaloneExpanders = expanders.filter(function (exp) {
    if (controllerExpanders.includes(exp)) return false;

    return true;
  });

  standaloneExpanders.forEach(function (expanderEl) {
    const trigger = expanderEl.querySelector<HTMLElement>(
      ".js-expanderTrigger"
    );

    if (!trigger) return;

    new Expander({
      el: expanderEl,
      triggerEl: trigger,
      defaultOpen: false,
      mobileOnly: expanderEl.dataset.expanderDesktopDisabled === "true",
    });
  });

  controllers.forEach(function (controller) {
    const expanderEls = [
      ...controller.querySelectorAll<HTMLElement>(".js-expander"),
    ];

    const expanders = expanderEls.map(function (expanderEl) {
      const trigger = expanderEl.querySelector<HTMLElement>(
        ".js-expanderTrigger"
      )!;
      return new Expander({
        el: expanderEl,
        triggerEl: trigger,
        defaultOpen: false,
        mobileOnly: expanderEl.dataset.expanderDesktopDisabled === "true",
      });
    });

    const expanderController = new ExpandersController(expanders, false);
  });
}

function dropdownInit() {
  const dropdowns = document.querySelectorAll<HTMLElement>(".js-dropdown");

  dropdowns.forEach(function (dropdown) {
    new CustomDropdown(dropdown);
  });
}

function modalInit() {
  const modals = document.querySelectorAll<HTMLElement>(".js-modal");
  modals.forEach(function (modal) {
    const trigger = modal.querySelector<HTMLButtonElement>(".js-modalTrigger");
    const content = modal.querySelector<HTMLElement>(".js-modalContent");
    const expanded = modal.querySelector<HTMLElement>(".js-modalExpanded");
    const close = modal.querySelector<HTMLButtonElement>(".js-modalClose");
    if (!trigger || !content || !expanded) {
      return;
    }
    new Modal(trigger, expanded, content, close);
  });
}

function popoverInit() {
  const infoPopovers = document.querySelectorAll<HTMLElement>(".js-popover");

  infoPopovers.forEach(function (el) {
    const trigger = el.querySelector<HTMLElement>(".js-popoverTrigger")!;
    const tooltip = new Expander({
      el,
      triggerEl: trigger,
      defaultOpen: false,
      hover: true,
    });
    window.addEventListener("scroll", () => {
      tooltip.close();
    });
  });
}

window.addEventListener("load", function () {
  const lazyLoadInstance = new (window as any).LazyLoad();
  inViewObserver();
  nav();
  expanderInit();
  dropdownInit();
  carouselsInit();
  modalInit();
  videoInit();
  truncaterInit();
  iframeLazyLoadInit();
  popoverInit();
  forms();
  initialiseBasketEvents();
  addToBasketTrigger();
});
