export default function () {
  const iframes =
    document.querySelectorAll<HTMLIFrameElement>(".js-iframeLoader");
  iframes.forEach(function (iframe) {
    const iframeSrc = iframe.dataset.src || "";

    iframe.addEventListener("load", function () {
      iframe.parentElement?.classList.add("iframeLoaded");
    });

    if (iframeSrc) {
      iframe.setAttribute("src", iframeSrc);
    }
  });
}
