export function initialiseBasketEvents() {
  const basketIcons = document.querySelectorAll<HTMLElement>(".js-basketIcon");

  const basketPreviews =
    document.querySelectorAll<HTMLElement>(".js-basketPreview");

  basketPreviews.forEach((basketPreview) => {
    const closePreview = basketPreview.querySelector<HTMLElement>(
      ".js-basketPreviewClose"
    )!;

    let clearBasketClassTimeout: null | NodeJS.Timeout = null;
    function closeBasketPreview() {
      basketPreview!.setAttribute("aria-hidden", "true");

      if (clearBasketClassTimeout) {
        clearTimeout(clearBasketClassTimeout);
        clearBasketClassTimeout = null;
      }

      clearBasketClassTimeout = setTimeout(() => {
        basketPreview!.classList.remove(
          "basket-preview--message",
          "basket-preview--preview"
        );
      }, 500);
    }

    document.addEventListener("click", (e) => {
      if (!basketPreview.hasAttribute("aria-hidden")) {
        const target = e.target as Element;
        if (
          target.closest(".js-basketPreview") ||
          target.classList.contains("js-basketIcon") ||
          target.classList.contains("js-addToBasket")
        )
          return;
        closeBasketPreview();
      }
    });

    function clearBasketTimeout() {
      if (clearBasketClassTimeout) {
        clearTimeout(clearBasketClassTimeout);
        clearBasketClassTimeout = null;
      }
    }

    basketIcons.forEach((basketIcon) => {
      basketIcon.addEventListener("click", () => {
        clearBasketTimeout();
        if (basketPreview.getAttribute("aria-hidden") === "true") {
          basketPreview.removeAttribute("aria-hidden");
          basketPreview.classList.add("basket-preview--preview");
        }
      });
    });

    closePreview.addEventListener("click", () => {
      closeBasketPreview();
    });
  });
}

export function updateBasketTotals(total: string) {
  const basketTotals = document.querySelectorAll<HTMLElement>(".js-basketTotal");

  basketTotals.forEach((basketTotal) => {
    basketTotal.innerText = total;
  });
}