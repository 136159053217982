import YTPlayer from "youtube-player";
import { YouTubePlayer } from "youtube-player/dist/types";
import VimeoPlayer from "@vimeo/player";

const YOUTUBE_REGEX =
  /(?:https?:\/\/)?(?:youtu\.be\/|(?:www\.|m\.)?youtube\.com\/(?:watch|v|embed)(?:\.php)?(?:\?.*v=|\/))([a-zA-Z0-9\_-]+)/;
const YTRegex = new RegExp(YOUTUBE_REGEX);

class VideoPlayer {
  constructor(
    public containerEl: HTMLElement,
    public videoPlayerContainer: HTMLElement,
    public playButton: HTMLButtonElement
  ) {
    this.addListeners();
  }

  addListeners() {
    this.playButton.addEventListener("click", () => this.onClick());

    const containingModal = this.containerEl.closest(".js-modalExpanded");

    if (containingModal) {
      containingModal.addEventListener("modal-close", () => {
        console.log("pausing");
        this.pauseVideo();
      });
    }
  }

  onClick() {
    // ...
  }

  pauseVideo() {}

  setPlayerDimensions(width: number | string, height: number | string) {
    this.containerEl.style.setProperty("--_width", `${width}`);
    this.containerEl.style.setProperty("--_height", `${height}`);
  }

  hidePoster() {
    this.containerEl.classList.add("active");
  }

  //   playVideo() {}
}

class NativeVideoPlayer extends VideoPlayer {
  player?: HTMLVideoElement;
  constructor(
    public containerEl: HTMLElement,
    public videoPlayerContainer: HTMLElement,
    public playButton: HTMLButtonElement,
    public videoSrc: string
  ) {
    super(containerEl, videoPlayerContainer, playButton);
  }

  onClick() {
    if (!this.player) {
      this.player = this.createNativeVideoPlayer(this.videoSrc);
      this.playVideo();
    }
  }

  pauseVideo(): void {
    if (this.player) {
      this.player.pause();
    }
  }

  createNativeVideoPlayer(videoSrc: string) {
    const videoEl = document.createElement("video");
    const source = document.createElement("source");

    videoEl.autoplay = false;
    videoEl.controls = true;
    videoEl.preload = "metadata";

    source.src = videoSrc;

    this.videoPlayerContainer.appendChild(videoEl);
    videoEl.appendChild(source);

    videoEl.addEventListener("loadedmetadata", () => {
      this.setPlayerDimensions(videoEl.videoWidth, videoEl.videoHeight);
    });
    return videoEl;
  }

  playVideo() {
    this.hidePoster();
    this.player?.play();
  }
}

class YoutubeVideoPlayer extends VideoPlayer {
  player?: YouTubePlayer;

  constructor(
    public containerEl: HTMLElement,
    public videoPlayerContainer: HTMLElement,
    public playButton: HTMLButtonElement,
    public videoId: string
  ) {
    super(containerEl, videoPlayerContainer, playButton);
  }

  async setPlayerSize() {
    if (this.player) {
      const iframe = await this.player.getIframe();
      this.setPlayerDimensions(iframe.width, iframe.height);
      iframe.setAttribute("data-ot-ignore", "");
    }
  }

  async onClick() {
    if (!this.player) {
      this.player = YTPlayer(this.videoPlayerContainer);
    }
    await this.setPlayerSize();
    await this.player.loadVideoById(this.videoId);
    this.hidePoster();
  }

  pauseVideo() {
    if (this.player) {
      this.player.pauseVideo();
    }
  }
}

class VimeoVideoPlayer extends VideoPlayer {
  player?: VimeoPlayer;

  constructor(
    public containerEl: HTMLElement,
    public videoPlayerContainer: HTMLElement,
    public playButton: HTMLButtonElement,
    public videoId: string
  ) {
    super(containerEl, videoPlayerContainer, playButton);
  }

  async setPlayerSize() {
    if (this.player) {
      const width = await this.player.getVideoWidth();
      const height = await this.player.getVideoHeight();
      this.setPlayerDimensions(width, height);
      const iframe = this.videoPlayerContainer.querySelector("iframe");
      if (iframe) {
        iframe.setAttribute("data-ot-ignore", "");
      }
    }
  }

  async onClick() {
    if (!this.player) {
      this.player = new VimeoPlayer(this.videoPlayerContainer, {
        url: this.videoId,
      });
    }
    await this.setPlayerSize();

    this.hidePoster();
    await this.player.play();
  }

  async pauseVideo() {
    if (this.player) {
      await this.player.pause();
    }
  }
}

export default function () {
  const videoEls = document.querySelectorAll<HTMLElement>(".js-video");

  videoEls.forEach(function (el) {
    const datasetVideoUrl = el.dataset.videoUrl || "";
    const videoPlayerContainer = el.querySelector<HTMLElement>(
      ".js-videoPlayerContainer"
    );
    const playButton = el.querySelector<HTMLButtonElement>(".js-videoPlay");

    if (!videoPlayerContainer || !playButton) return;

    // Youtuve
    let isYoutube = false;
    let videoId = "";

    const ytRegexExec = YTRegex.exec(datasetVideoUrl);
    if (ytRegexExec && ytRegexExec.length > 1) {
      isYoutube = true;

      videoId = ytRegexExec[1];
    }

    if (isYoutube) {
      new YoutubeVideoPlayer(el, videoPlayerContainer, playButton, videoId);
    } else if (datasetVideoUrl.includes("vimeo")) {
      new VimeoVideoPlayer(
        el,
        videoPlayerContainer,
        playButton,
        datasetVideoUrl
      );
    } else {
      new NativeVideoPlayer(
        el,
        videoPlayerContainer,
        playButton,
        datasetVideoUrl
      );
    }
  });
}
