//
// expandedEl for the whole screen overlay/container
// contentEl for main content window

class Modal {
  constructor(
    public triggerEl: HTMLElement | null,
    public expandedEl: HTMLElement,
    public contentEl: HTMLElement,
    public closeEl?: HTMLButtonElement | null
  ) {
    this.addListeners();
    document.body.appendChild(this.expandedEl);
  }

  addListeners() {
    document.addEventListener("keydown", (e) => this.onKeydown(e));
    this.triggerEl?.addEventListener("click", () => this.open());
    this.contentEl.addEventListener("click", (e) => e.stopPropagation());
    this.expandedEl.addEventListener("click", () => this.close());
    this.closeEl?.addEventListener("click", () => this.close());
  }

  onKeydown({ key }: KeyboardEvent) {
    if (key === "Escape") {
      this.close();
    }
  }

  open() {
    this.triggerEl?.setAttribute("aria-expanded", "true");
    this.expandedEl.removeAttribute("aria-hidden");

    if (this.closeEl) {
      setTimeout(() => {
        this.closeEl!.focus();
      }, 100);
    }
  }

  close() {
    // this.el.classList.remove('active');
    this.triggerEl?.setAttribute("aria-expanded", "false");
    this.expandedEl.setAttribute("aria-hidden", "true");

    // Fire close event for videos to hook onto
    var event = new Event("modal-close");
    this.expandedEl.dispatchEvent(event);

    setTimeout(() => {
      this.triggerEl?.focus();
    }, 100);
  }
}

export default Modal;
