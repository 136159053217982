import Modal from "./modal";
import { initialiseBasketEvents, updateBasketTotals } from './basket';

function fetchBundleContent(contentKey: string, callback: (response: string) => void) {
  const xhr = new XMLHttpRequest();
  xhr.open("POST", "/umbraco/surface/basketsurface/addtobasketcheck/", true);
  xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");

  xhr.onreadystatechange = function () {
    if (xhr.readyState === 4) {
      if (xhr.status === 200) {
        callback(xhr.responseText);
      } else {
        console.error("Network response was not ok");
        callback(`<div class="bundle-modal__inner">
          <div class="bundle-modal__heading">Error loading content</div>
          <div class="rte bundle-modal__description">
            <p>There was an error loading the content. Please try again later.</p>
          </div>
        </div>`);
      }
    }
  };

  const params = new URLSearchParams();
  params.append("contentKey", contentKey);
  xhr.send(params.toString());
}

function sendAddToBasket(formData: FormData, onSuccess: (result: string) => void) {
  const formEntries = Object.fromEntries(formData.entries());

  function convertToProperTypes(entries: { [key: string]: FormDataEntryValue }): { [key: string]: any } {
    const convertedEntries: { [key: string]: any } = {};
    for (const [key, value] of Object.entries(entries)) {
      if (value === 'True' || value === 'False') {
        convertedEntries[key] = value === 'True';
      } else if (!isNaN(value as any) && key.toLowerCase() === 'quantity') {
        convertedEntries[key] = parseInt(value as string, 10);
      } else {
        convertedEntries[key] = value;
      }
    }
    return convertedEntries;
  }

  const transformedEntries = convertToProperTypes(formEntries);

  // console.log(JSON.stringify(transformedEntries));

  fetch("/umbraco/surface/basketsurface/addproducttobasketpost/", {
    method: "POST",
    body: JSON.stringify(transformedEntries),
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'text/html',
      // 'RequestVerificationToken': transformedEntries["__RequestVerificationToken"].toString()
    }
  })
    .then(response => {
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      // console.log(response);
      return response.text();
    })
    .then(result => {
      onSuccess(result); // Call the success callback
    })
    .catch(error => {
      console.error("Form submission error", error);
      // Handle form submission error (e.g., show an error message)
    });
}


//Show add to basket notice
function addedToBasket(formData: FormData) {
  sendAddToBasket(formData, (result: string) => {
    const basketPreviews =
      document.querySelectorAll<HTMLElement>(".js-basketPreview");
    basketPreviews.forEach((basketPreview) => {
      if (basketPreview) {
        const virtualNode = document.createElement('div');
        virtualNode.innerHTML = result;

        const newContent = virtualNode.firstElementChild as HTMLElement;

        // Apply necessary classes and attributes to the new content
        if (newContent.hasAttribute("aria-hidden")) {
          newContent.classList.add("basket-preview--message");
          newContent.removeAttribute("aria-hidden");
        }

        // Replace the inner HTML of the basketPreview with the new content
        basketPreview.innerHTML = '';
        basketPreview.replaceWith(newContent);

        var basketTotal = newContent.getAttribute("data-total");
        updateBasketTotals(basketTotal || "0");
      }
    });

    initialiseBasketEvents();
  });
}

function destroyBundleModal(modalEl: HTMLElement) {
  setTimeout(() => {
    modalEl.remove();
  }, 500);
}

function createBundleModal(event: Event, formData: FormData, contentKey: string) {

  event.preventDefault();

  fetchBundleContent(contentKey, function (response) {

    var popupHTML = new String(response)

    if (popupHTML.length > 0) {
      const expanded = document.createElement("div");
      expanded.classList.add("tag-modal", "tag-modal--bundle");

      const content = document.createElement("div");
      content.classList.add(
        "tag-modal-dialog",
        "bundle-modal",
        "container-margin-left--xs",
        "container-margin-right--xs",
        "js-bundleModal"
      );

      content.innerHTML = response;

      expanded.appendChild(content);
      document.body.appendChild(expanded);

      const oldBundle = content.querySelector(".js-keepBundle")!;
      const newBundle = content.querySelector(".js-replaceBundle")!;

      const modal = new Modal(null, expanded, content);

      oldBundle.addEventListener("click", () => {
        modal.close();
        destroyBundleModal(expanded);
      });
      newBundle.addEventListener("click", () => {
        modal.close();
        destroyBundleModal(expanded);
        addedToBasket(formData);
      });
    }
    else {
      addedToBasket(formData);
    }
  });
}

export function addToBasketTrigger() {
  const forms = document.querySelectorAll<HTMLFormElement>("form.js-addToBasket");
  forms.forEach((form) => {
    const contentKeyEl = form.querySelector<HTMLInputElement>(".js-productKey");
    const contentKey = contentKeyEl?.value || '';
    const formData = new FormData(form);
    form.addEventListener("submit", (event) => createBundleModal(event, formData, contentKey));
  });
  const links = document.querySelectorAll<HTMLAnchorElement>("a.js-addToBasket");
  links.forEach((link) => {
    const contentKey = link.getAttribute("data-key") || '';
    const quantity = link.getAttribute("data-quantity") || '';
    const removeOtherBundles = (link.getAttribute("data-removeOtherBundles") || '0') == '1' ? 'True' : 'False';
    const removeBundledProducts = (link.getAttribute("data-removeBundledProducts") || '0') == '1' ? 'True' : 'False';

    const formData = new FormData();
    formData.append('quantity', '1');
    formData.append('__Invariant', quantity);
    formData.append('ProductKey', contentKey);
    formData.append('RemoveBundledProducts', removeBundledProducts);
    formData.append('RemoveOtherBundles', removeOtherBundles);
    //formData.append('__RequestVerificationToken', 'CfDJ8NXWeVTxqexFsP8lH5zrY5iRPg-y3lZsZvCHPiEpfAxPjVIssB_LzsE5B5jUh5TzL_elElbG6dcsNDSMQTYAFnHZvRdXFSKy0wPCHtyXTuJ4kBad-SNOpIGrdRdwJTJAsmeQVggWYufG1vWGuzka1Q4');
    //formData.append('ufprt', 'CfDJ8NXWeVTxqexFsP8lH5zrY5iz4GZUPs2QmGz2_whEWMuHEe0GLpjz_jN7aKSxlqdC_IBFy5MTuTz_oHo2EZ1JNWN0WEgX12XuEzOGl5nfz_Oa5B2cFasNjcOzVFpIbgoZ1BZCy4Hj-Zg3IAPQs9zEWPRgqx6-PAlmZboPQWPkCe9w');

    link.addEventListener("click", (event) => createBundleModal(event, formData, contentKey));
  });
}
