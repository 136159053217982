import Swiper, { Navigation, Pagination } from "swiper";

export function createCarousel(wrapperEl: HTMLElement) {
  const carouselEl = wrapperEl.classList.contains("swiper")
    ? wrapperEl
    : wrapperEl.querySelector<HTMLElement>(".swiper");
  if (!carouselEl) return;
  const bullets = wrapperEl.querySelector<HTMLElement>(".js-carouselBullets");
  const progressbar = wrapperEl.querySelector<HTMLElement>(".js-progressbar");
  const prevBtn = wrapperEl.querySelector<HTMLElement>(".js-carouselPrev")!;
  const nextBtn = wrapperEl.querySelector<HTMLElement>(".js-carouselNext")!;
  const slidesContainer =
    wrapperEl.querySelector<HTMLElement>(".swiper-wrapper")!;
  const slides = wrapperEl.querySelectorAll<HTMLElement>(".swiper-slide");

  const carousel = new Swiper(carouselEl, {
    modules: [Navigation, Pagination],
    pagination: {
      el: bullets,
      type: "bullets",
    },
    navigation: {
      nextEl: nextBtn,
      prevEl: prevBtn,
    },
    slidesPerView: "auto",
    spaceBetween: 0,
    // slidesPerGroupAuto: true,
    speed: 1000,
    centerInsufficientSlides: true,

    breakpoints: {
      1024: {
        slidesPerView: "auto",
        spaceBetween: 0,
      },
    },
    on: {
      afterInit(swiper) {
        if (window.innerWidth > 1023 && slides.length > 2) {
          // To allow last slide to be active on desktop
          const addedSlide = slidesContainer.appendChild(
            slides[0].cloneNode(true)
          ) as HTMLElement;
          addedSlide.classList.add("invisible");
          addedSlide.setAttribute("aria-hidden", "true");
        }
      },
    },
  });

  return carousel;
}

export default function () {
  const carousels = document.querySelectorAll<HTMLElement>(".js-carousel");

  carousels.forEach(function (c) {
    createCarousel(c);
  });
}
