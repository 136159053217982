const CHARACTER_LIMIT = 300;

export default function () {
  const truncaters = document.querySelectorAll<HTMLElement>(".js-truncate");

  truncaters.forEach(function (truncater) {
    const trigger = truncater.parentElement!.querySelector(
      ".js-truncateReadMore"
    );
    let originalContent = "";

    if (trigger) {
      trigger.remove();
      originalContent = truncater.textContent!;
      truncater.textContent =
        truncater.textContent!.trim().slice(0, CHARACTER_LIMIT).trim() + "... ";
      truncater.appendChild(trigger);
      trigger.addEventListener("click", function () {
        truncater.textContent = originalContent;
        truncater.classList.add("expanded");
      });
    } else {
      originalContent = truncater.textContent!;
      truncater.textContent!.trim().slice(0, CHARACTER_LIMIT).trim() + "... ";
    }
  });
}
