import { createCarousel } from "./carousels";
import { throttle } from "./utils";

export default function () {
  const header = document.querySelector<HTMLElement>("header")!;
  const main = document.querySelector<HTMLElement>("main")!;

  const mainNavigation =
    header.querySelector<HTMLElement>(".js-mainNavigation");
  const secondaryNav = document.querySelector<HTMLElement>(".js-secondaryNav");

  const mobileNavToggle = document.querySelector<HTMLButtonElement>(
    ".js-mobileNavToggle"
  );

  if (secondaryNav) {
    header.classList.add("has-secondary-nav");
  }

  // Hidden mobile navigation
  if (mainNavigation) {
    if (window.innerWidth < 1024) {
      mainNavigation.setAttribute("aria-hidden", "true");
    } else {
      mainNavigation.removeAttribute("aria-hidden");
    }
  }

  if (mobileNavToggle && mainNavigation) {
    mobileNavToggle.addEventListener("click", function () {
      mobileNavToggle.classList.toggle("burger-menu--active");
      header.classList.toggle("active");
      if (mainNavigation.hasAttribute("aria-hidden")) {
        mainNavigation.removeAttribute("aria-hidden");
        mobileNavToggle.setAttribute("aria-expanded", "true");
      } else {
        mainNavigation.setAttribute("aria-hidden", "true");
        mobileNavToggle.removeAttribute("aria-expanded");
      }
    });
  }

  let lastScrollPos = 0;
  let height = 0;

  const onScroll = throttle(() => {
    if (window.scrollY < lastScrollPos) {
      header.classList.remove("scrolled-down");
    } else {
      header.classList.add("scrolled-down");
    }
    if (window.scrollY === 0) {
      header.classList.remove("scrolled-down");
    }
    lastScrollPos = window.scrollY;

    if (secondaryNav) {
      header.style.position = "relative";
      const observer = new IntersectionObserver(
        ([e]) => e.target.classList.toggle("isSticky", e.intersectionRatio < 1),
        { threshold: [1] }
      );

      observer.observe(secondaryNav);
    }

    // Update only if header height changes
    if (height != header.offsetHeight) {
      height = header.offsetHeight;
      updateGlobalVars();
    }
  }, 100);

  onScroll();
  updateGlobalVars();

  window.addEventListener("scroll", onScroll);

  if (mainNavigation) {
    navigation(mainNavigation, header, main);
  }
}

interface ToggleEvent extends Event {
  expanded: boolean;
  el: HTMLElement;
}

export class NavigationController {
  constructor(
    public navItems: NavigationItem[],
    public header: HTMLElement,
    public main: HTMLElement
  ) {
    this.addListeners();
  }

  addListeners() {
    this.navItems.forEach((item) => {
      item.el.addEventListener("toggled", ((e) => {
        const detail = (e as CustomEvent).detail;
        this.onToggle(detail);
      }) as EventListener);
    });

    this.main.addEventListener("click", ((e) => {
      if (this.header.classList.contains("active")) {
        this.navItems.forEach((item) => {
          item.close();
          this.header.classList.toggle("active");
        });
      }
    }) as EventListener);
  }

  onToggle({ el, expanded }: ToggleEvent) {
    if (!expanded) {
      this.navItems.forEach((item) => {
        item.close();
        this.header.classList.remove("active");
      });
    } else {
      this.navItems.forEach((item) => {
        this.header.classList.add("active");
        if (item.el !== el) {
          item.close();
        }
      });
    }
  }
}
class NavigationItem {
  expanded: boolean = false;
  enabled: boolean = true;

  constructor(
    public el: HTMLElement,
    public triggerEl: HTMLButtonElement,
    public contentEl: HTMLElement,
    public backButton: HTMLButtonElement,
    public callback?: () => void
  ) {
    this.addListeners();
  }

  addListeners() {
    this.triggerEl.addEventListener("click", () => this.onClick());

    this.backButton.addEventListener("click", () => {
      if (this.expanded) {
        this.close();
      }
    });
  }

  onClick() {
    if (this.callback) {
      this.callback();
    }

    if (!this.enabled) return;
    if (this.expanded) {
      this.close();
    } else {
      this.open();
    }
    this.fireCustomEvent();
  }

  open() {
    this.expanded = true;

    this.contentEl.removeAttribute("aria-hidden");
    // this.contentEl.removeAttribute("inert");
    this.el.classList.add("active");
  }

  close() {
    this.expanded = false;

    this.contentEl.setAttribute("aria-hidden", "true");
    // this.contentEl.setAttribute("inert", "");
    this.el.classList.remove("active");
  }

  enable() {
    this.enabled = true;
  }

  disable() {
    this.enabled = false;
  }

  fireCustomEvent() {
    const expandChange = new CustomEvent("toggled", {
      detail: { expanded: this.expanded, el: this.el },
    });
    this.el.dispatchEvent(expandChange);
  }
}

function navigation(
  mainNavigation: HTMLElement,
  header: HTMLElement,
  main: HTMLElement
) {
  const navItems = [
    ...mainNavigation.querySelectorAll<HTMLElement>(
      ".main-nav__top > .js-navItem"
    ),
  ];

  const navItemsNested = [
    ...mainNavigation.querySelectorAll<HTMLElement>(".js-navItem .js-navItem"),
  ];

  const topLevelNavItems: NavigationItem[] = navItems.reduce(function (
    acc: NavigationItem[],
    item
  ) {
    const triggerEl =
      item.querySelector<HTMLButtonElement>(".js-navItemTrigger");
    const contentEl = item.querySelector<HTMLElement>(".js-navItemExpanded");
    const backButton = item.querySelector<HTMLButtonElement>(".js-navItemBack");

    if (!triggerEl || !contentEl || !backButton) return acc;

    const carouselEl = item.querySelector<HTMLElement>(".js-headerCarousel");

    if (carouselEl) {
      createCarousel(carouselEl);
    }

    const navItem = new NavigationItem(
      item,
      triggerEl,
      contentEl,
      backButton,
      updateGlobalVars
    );
    return [...acc, navItem];
  },
  []);

  const nestedNavItems: NavigationItem[] = navItemsNested.reduce(function (
    acc: NavigationItem[],
    item
  ) {
    // If there's only 1 selector nested then use the parent selector as trigger instead
    const numMobileVisibleBlocks = [
      ...(item.parentElement?.children || []),
    ].filter((i) => !i.classList.contains("hidden")).length;

    const triggerEl =
      numMobileVisibleBlocks > 1
        ? item.querySelector<HTMLButtonElement>(".js-navItemTrigger")
        : item.parentElement
            ?.closest(".js-navItem")
            ?.querySelector<HTMLButtonElement>(".js-navItemTrigger");
    const contentEl = item.querySelector<HTMLElement>(".js-navItemExpanded");
    const backButton = item.parentElement
      ?.closest(".js-navItem")
      ?.querySelector<HTMLButtonElement>(".js-navItemBack");

    if (!triggerEl || !contentEl || !backButton) return acc;

    const navItem = new NavigationItem(item, triggerEl, contentEl, backButton);
    if (window.innerWidth >= 1024) {
      navItem.open();
      navItem.disable();
    }
    return [...acc, navItem];
  },
  []);

  // new NavigationController([...topLevelNavItems, ...nestedNavItems]);
  // const topLevelController = new NavigationController(
  //   topLevelNavItems,
  //   header,
  //   main
  // );
}

function updateGlobalVars() {
  const header = document.querySelector<HTMLElement>("header");
  const secondaryNav = document.querySelector<HTMLElement>(".js-secondaryNav");

  if (header) {
    if (window.innerWidth < 1024) {
      document.body.style.setProperty(
        "--header-height--mobile",
        `${header.offsetHeight}px`
      );
    } else {
      document.body.style.setProperty(
        "--header-height--desktop",
        `${header.offsetHeight}px`
      );
    }
  }
  if (secondaryNav) {
    if (window.innerWidth < 1024) {
      document.body.style.setProperty(
        "--secondary-header-height--mobile",
        `${secondaryNav.offsetHeight}px`
      );
    } else {
      document.body.style.setProperty(
        "--secondary-header-height--desktop",
        `${secondaryNav.offsetHeight}px`
      );
    }
  }
}
