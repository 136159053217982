import flatpickr from "flatpickr";

export default function () {
  //Qty Field
  const qtys = document.querySelectorAll<HTMLElement>(".js-qty");
  qtys.forEach((qty) => {
    const add = qty.querySelector<HTMLElement>(".js-qtyAdd");
    const minus = qty.querySelector<HTMLElement>(".js-qtyMinus");
    const input = qty.querySelector<HTMLInputElement>(".js-qtyInput");

    if (!add || !minus || !input) return;

    add.addEventListener("click", () => {
      const newVal = parseInt(input.value) + 1;
      if (newVal <= parseInt(input.max) || !input.max)
        input.value = newVal.toString();
    });
    minus.addEventListener("click", () => {
      const newVal = parseInt(input.value) - 1;
      if (newVal >= parseInt(input.min) || !input.min)
        input.value = newVal.toString();
    });
  });
  //Calendar Field
  const config = {
    shorthandCurrentMonth: true,
    dateFormat: "d/m/Y",
    allowInput: true,
  };
  flatpickr(".js-calendar", config);
}
