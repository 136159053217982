interface IExpander {
  el: HTMLElement;
  triggerEl: HTMLElement;
  targetEl?: HTMLElement;
  activeClass?: string;
  defaultOpen: boolean;
  mobileOnly?: boolean;
  hover?: boolean;
}

interface ToggleEvent extends Event {
  expanded: boolean;
  el: HTMLElement;
}

class Expander implements IExpander {
  el!: HTMLElement;
  triggerEl!: HTMLElement;
  targetEl?: HTMLElement;
  activeClass = "active";
  defaultOpen = false;
  mobileOnly = false;
  expanded = false;
  hover = false;

  constructor(options: IExpander) {
    Object.assign(this, options);

    if (this.mobileOnly && window.innerWidth > 1023) {
      this.open();
      this.el.removeAttribute("aria-expanded");
      return;
    }

    this.triggerEl.addEventListener("click", () => this.toggle());

    if (this.hover) {
      this.el.addEventListener("mouseenter", () => this.toggle());
      this.el.addEventListener("mouseleave", () => this.toggle());
    }

    if (this.defaultOpen) {
      this.open();
    }
  }

  toggle() {
    if (this.targetEl) {
      this.targetEl.classList.toggle(this.activeClass);
    } else {
      this.el.classList.toggle(this.activeClass);
    }

    this.expanded = !this.expanded;

    this.el.setAttribute("aria-expanded", this.expanded ? "true" : "false");

    const expandChange = new CustomEvent("toggled", {
      detail: { expanded: this.expanded, el: this.el },
    });
    this.el.dispatchEvent(expandChange);
  }

  open() {
    this.expanded = true;
    this.el.classList.add(this.activeClass);

    this.el.setAttribute("aria-expanded", "true");
  }

  close() {
    this.expanded = false;
    this.el.classList.remove(this.activeClass);
    this.el.setAttribute("aria-expanded", "false");
  }
}

// For handling multiple expanders in a group
export class ExpandersController {
  constructor(public expanders: Expander[], private syncAll?: Boolean) {
    this.addListeners();
  }

  addListeners() {
    this.expanders.forEach((expander) => {
      expander.el.addEventListener("toggled", ((e) => {
        const detail = (e as CustomEvent).detail;
        this.onToggle(detail);
      }) as EventListener);
    });
  }

  onToggle({ el, expanded }: ToggleEvent) {
    this.expanders.forEach((expander) => {
      if (this.syncAll) {
        if (expanded) {
          expander.open();
        } else {
          expander.close();
        }
        return;
      }

      if (expander.el !== el) {
        expander.close();
      }
    });
  }
}

export default Expander;
